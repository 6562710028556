import { API, AUTHENTICATION, AUTHORIZATION, ENV_VAR, NAVIGATION_URL, ORGANIZATION_API, REDIRECT, FAVICON_URL } from "./api";
import { Environment } from "./environment";

export const commonEnv = {
  env: ENV_VAR.LOCAL,
  hostname: API.DEV,
  REDIRECT_URL_DEV: AUTHENTICATION.BCCONNECTWEB.LOCAL,
  GET_USER_SESSION: AUTHORIZATION.GET_USER_SESSION.LOCAL,
  GET_SYSTEM_NOTIFICATIONS: AUTHORIZATION.GET_SYSTEM_NOTIFICATIONS.LOCAL,
  ORGANIZATION_API_ENDPOINT: ORGANIZATION_API.ENDPOINT.LOCAL,
  FAVICON: FAVICON_URL.LOCAL,
  REDIRECT: {
    BCCONNECTDOMAIN: REDIRECT.BCCONNECTDOMAIN.LOCAL,
    BCCONNECTSUBDOMAIN: REDIRECT.BCCONNECTSUBDOMAIN.LOCAL,
    BCOPTISUBDOMAIN: REDIRECT.BCOPTISUBDOMAIN.LOCAL,
    BCCALSUBDOMAIN: REDIRECT.BCCALCSUBDOMAIN.LOCAL
  },
  AUTHENTICATION: {
    AUTH_BASE_URL: AUTHENTICATION.AUTH_APP_URL.LOCAL,
    REDIRECT_URL: {
      BCCONNECTAPP: AUTHENTICATION.BCCONNECTAPP.LOCAL,
      BCCONNECTWEB: AUTHENTICATION.BCCONNECTWEB.LOCAL,
      BCCALC: AUTHENTICATION.BCCALC.LOCAL,
      BCOPT: AUTHENTICATION.BCOPT.LOCAL,
      BCCALCPUBLIC: AUTHENTICATION.BCCALCPUBLIC.LOCAL,
    },
  },
  prod: false,
  //Cleanup - Remove PR_PUB_SER and replace all uses with PUBLIC_SERVICES
  NAVIGATION_OBJECT_URL: NAVIGATION_URL.LOCAL,

  BCC_CONNECT_LOGO:
    "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/logo/bcconnect_logo_2023.png",
  BC_LOGO:
    "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/logo/BC_logo_362_EWP_outline_r.png",
  BCC_ICON: "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/apps/",
  LAUNCH_DARKLY_CLIENT_ID: "64346d1bb0477213a34c1e5a"
};
