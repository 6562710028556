const HOSTNAME = {
  LOCAL: "localhost",
  DEV: "",
  STABLE:"",
  INT: "",
  STAGE: "",
  PROD: "",
};

export const ENV_VAR = {
  LOCAL: "local",
  DEV: "dev",
  STABLE: "stable",
  INT: "int",
  STAGE: "stage",
  PROD: "prod"
}

export const API = {
  LOCAL: HOSTNAME.LOCAL,
  DEV: HOSTNAME.DEV,
  STABLE: HOSTNAME.STABLE,
  INT: HOSTNAME.INT,
  STAGE: HOSTNAME.STAGE,
  PROD: HOSTNAME.PROD,
};

export const NAVIGATION_URL = {
  LOCAL: "https://shared-layout-local.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json",
  DEV: "https://shared-layout-dev.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json",
  STABLE: "https://shared-layout-stable.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json",
  INT: "https://shared-layout-int.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json",
  STAGE: "https://shared-layout-stage.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json",
  PROD: "https://shared-layout-prod.s3.us-west-2.amazonaws.com/nav-data/bcconnect-navigation-menu.json"
};

export const FAVICON_URL = {
  LOCAL: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico",
  DEV: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico",
  STABLE: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico",
  INT: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico",
  STAGE: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico",
  PROD: "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico"
};

export const AUTHENTICATION = {
  AUTH_APP_URL: {
    LOCAL: "http://authentication.dev.bccapp.com",
    DEV: "https://authentication.dev.bccapp.com",
    STABLE: "https://authentication.stable.dev.bccapp.com",
    INT: "https://authentication.int.bccapp.com",
    STAGE: "https://authentication.stage.bcconnect.com",
    PROD: "https://authentication.bcconnect.com"
  },
  BCCONNECTAPP: {
    LOCAL: "http://localhost:4200",
    DEV: "https://www.new.dev.bccapp.com",
    STABLE: "https://www.stable.dev.bccapp.com",
    INT: "https://www.int.dev.bccapp.com",
    STAGE: "https://www.stage.dev.bcconnect.com",
    PROD: "https://www.new.bcconnect.com",
  },
  BCCONNECTWEB: {
    LOCAL: "https://local.bccapp.com",
    DEV: "https://dev.bccapp.com",
    STABLE: "https://stable.dev.bccapp.com",
    DEVPR1: "https://pr1.dev.bccapp.com",
    DEVPR2: "https://pr2.dev.bccapp.com",
    DEVPR3: "https://pr3.dev.bccapp.com",
    DEVPR4: "https://pr4.dev.bccapp.com",
    DEVPR5: "https://pr5.dev.bccapp.com",
    DEVPR6: "https://pr6.dev.bccapp.com",
    DEVPR7: "https://pr7.dev.bccapp.com",
    DEVPR8: "https://pr8.dev.bccapp.com",
    DEVPR9: "https://pr9.dev.bccapp.com",
    DEVPR10: "https://pr10.dev.bccapp.com",
    INT: "https://int.bccapp.com",
    STAGE: "https://stage.bcconnect.com",
    PROD: "https://bcconnect.com",
  },
  BCCALC: {
    LOCAL: "https://local.calc.bccapp.com",
    DEV: "https://bccalc-dev.bccapp.com",
    STABLE: "https://stable.bccalc-dev.bccapp.com",
    INT: "https://bccalc-int.bccapp.com",
    STAGE: "https://bccalc-stage.bcconnect.com",
    PROD: "https://calc.bcconnect.com",
  },
  BCOPT: {
    LOCAL: "https://local.optimizer.bccapp.com",
    DEV: "https://opti-dev.bccapp.com",
    STABLE: "https://stable.opti-dev.bccapp.com",
    INT: "https://opti-int.bccapp.com",
    STAGE: "https://opti-stage.bcconnect.com",
    PROD: "https://optimizer.bcconnect.com",
  },
  BCCALCPUBLIC: {
    LOCAL: "https://bccalc.com",
    DEV: "https://bccalc.com",
    STABLE: "https://bccalc.com",
    INT: "https://bccalc.com",
    STAGE: "https://bccalc.com",
    PROD: "https://bccalc.com",
  }
};

export const REDIRECT = {  
  BCCONNECTDOMAIN: {
    LOCAL: "bccapp.com",
    DEV: "bccapp.com",
    STABLE: "bccapp.com",
    DEVPR1: "bccapp.com",
    DEVPR2: "bccapp.com",
    DEVPR3: "bccapp.com",
    DEVPR4: "bccapp.com",
    DEVPR5: "bccapp.com",
    DEVPR6: "bccapp.com",
    DEVPR7: "bccapp.com",
    DEVPR8: "bccapp.com",
    DEVPR9: "bccapp.com",
    DEVPR10: "bccapp.com",
    INT: "bccapp.com",
    STAGE: "bcconnect.com",
    PROD: "bcconnect.com"
  },  
  BCCONNECTSUBDOMAIN: {
    LOCAL: "local",
    DEV: "dev",
    STABLE: "stable.dev",
    DEVPR1: "pr1.dev",
    DEVPR2: "pr2.dev",
    DEVPR3: "pr3.dev",
    DEVPR4: "pr4.dev",
    DEVPR5: "pr5.dev",
    DEVPR6: "pr6.dev",
    DEVPR7: "pr7.dev",
    DEVPR8: "pr8.dev",
    DEVPR9: "pr9.dev",
    DEVPR10: "pr10.dev",
    INT: "int",
    STAGE: "stage",
    PROD: ""
  },
  BCOPTISUBDOMAIN:{
    LOCAL: "local.optimizer",
    DEV: "opti-dev",
    STABLE: "stable.opti-dev",
    INT: "opti-int",
    STAGE: "opti-stage",
    PROD: "optimizer"
  },
  BCCALCSUBDOMAIN:{
    LOCAL: "local.calc",
    DEV: "bccalc-dev",
    STABLE: "stable.bccalc-dev",
    INT: "bccalc-int",
    STAGE: "bccalc-stage",
    PROD: "calc"
  }
};
export const AUTHORIZATION = {
  GET_USER_SESSION: {
    LOCAL: "https://authorization-microservice.local.bccapp.com/v1/user/session",
    DEV: "https://authorization-microservice.dev.bccapp.com/v1/user/session",
    STABLE: "https://authorization-microservice.stable.dev.bccapp.com/v1/user/session",
    INT: "https://authorization-microservice.int.bccapp.com/v1/user/session",
    STAGE: "https://authorization-microservice.stage.bcconnect.com/v1/user/session",
    PROD: "https://authorization-microservice.bcconnect.com/v1/user/session",
  },
  GET_SYSTEM_NOTIFICATIONS: {
    LOCAL: "https://authorization-microservice.local.bccapp.com/v1/systemnotifications",
    DEV: "https://authorization-microservice.dev.bccapp.com/v1/systemnotifications",
    STABLE: "https://authorization-microservice.stable.dev.bccapp.com/v1/systemnotifications",
    INT: "https://authorization-microservice.int.bccapp.com/v1/systemnotifications",
    STAGE: "https://authorization-microservice.stage.bcconnect.com/v1/systemnotifications",
    PROD: "https://authorization-microservice.bcconnect.com/v1/systemnotifications",
  }
};

export const ORGANIZATION_API = {
  ENDPOINT: {
    LOCAL: "https://api.dev.bccapp.com/api/",
    DEV: "https://api.dev.bccapp.com/api/",
    STABLE: "https://stable.api.dev.bccapp.com/api/",
    INT: "https://api.int.bccapp.com/api/",
    STAGE: "https://api.stage.bcconnect.com/api/",
    PROD: "https://api.bcconnect.com/api/"
  }
}
