import { Component } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { SystemNotification } from "../../interfaces/IUser";
import { INotificationDisplay } from "../../interfaces/INotificationDisplay";
import { UserService } from "../../service/user/user.service";
import { CookieOptions, CookieService } from "ngx-cookie-service";
import { COOKIES } from "../../utilities/constants";
import { getDomain } from "tldts";

@Component({
  selector: "notification-banner",
  templateUrl: "./notification-banner.component.html",
  styleUrls: ["./notification-banner.component.scss"],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)), // 500 milliseconds for the fade effect
    ])
  ]
})
export class NotificationBannerComponent {
  public notificationDisplayList: INotificationDisplay[];
  private cookieExpHours: number = 24;
  public dismissalText: string = `The notification will be dismissed for ${this.cookieExpHours} hours.`
  private cookieOpts: CookieOptions = {
    expires: 1, //cookieExpHours in days
    path: "/",
    domain: getDomain(window.location.origin),
    sameSite: 'Lax'
  };

  constructor(
    private userService: UserService,
    private cookieService: CookieService,
  ) {}

  public ngOnInit() {
    this.userService.getSystemNotifications().subscribe((notifications: SystemNotification[]) => {
      this.notificationDisplayList = this.mapSystemNotificationsToDisplayList(notifications);
    });
  }

  private mapSystemNotificationsToDisplayList(notifications: SystemNotification[]): INotificationDisplay[] {
    return notifications
      .filter((notification: SystemNotification) => {
        const isDismissed = this.cookieService.get(COOKIES.DISMISSED_NOTIFICATION_PREFIX + notification.SystemNotificationId);
        return !isDismissed; // Include only notifications that are not dismissed
      })
      .map((notification: SystemNotification) => ({
        id: notification.SystemNotificationId,
        text: notification.Text,
        showNotification: true,
      } as INotificationDisplay));
  }

  public dismissNotificationBanner(notificationDisplay: INotificationDisplay): void {
    this.cookieService.set(COOKIES.DISMISSED_NOTIFICATION_PREFIX + notificationDisplay.id, "true", this.cookieOpts)    ;
    notificationDisplay.text = this.dismissalText;
    setTimeout(() => {
      notificationDisplay.showNotification = false;
    }, 5000);
  }
}
