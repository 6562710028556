import { Environment } from "../../../environments/environment";

export enum Hostname {
  localhost = 0,
  bcconnect = 1,
  bccapp = 2,
  calc = 3,
  opti = 4,
  calcpublic = 5
}

export const HOSTURL = {
  local: Environment.AUTHENTICATION.REDIRECT_URL.BCCONNECTAPP, //0
  web: Environment.AUTHENTICATION.REDIRECT_URL.BCCONNECTWEB, //1
  app: Environment.AUTHENTICATION.REDIRECT_URL.BCCONNECTAPP, //2
  calc: Environment.AUTHENTICATION.REDIRECT_URL.BCCALC, //3
  opti: Environment.AUTHENTICATION.REDIRECT_URL.BCOPT, //4
  calcpublic: Environment.AUTHENTICATION.REDIRECT_URL.BCCALCPUBLIC //5
};

//look into read only
export const REDIRECT_HOST_MAP: Map<Hostname, string> = new Map([
  [Hostname.localhost, HOSTURL.local],
  [Hostname.bcconnect, HOSTURL.web],
  [Hostname.bccapp, HOSTURL.app],
  [Hostname.calc, HOSTURL.calc],
  [Hostname.opti, HOSTURL.opti],
  [Hostname.calcpublic, HOSTURL.calcpublic]
]);

export class TargetEnvHostnames {
  static PR = "pr";
  static CANARY = "canary";
  static LOCAL_HOST = "localhost";
}

export class QueryConstants {
  static ORGANIZATION_ID_BRACKETS = "{{organizationId}}";
  static ORGANIZATION_ID = "organizationId";
  static ATTACHMENT_ID_BRACKETS = "{{attachmentId}}";
  static ATTACHMENT_ID = "attachmentId";
  static PROJECT_ID_BRACKETS = "{{projectId}}";
  static PROJECT_ID = "projectId";
  static BUILDER_ID_BRACKETS = "{{builderId}}";
  static DEALER_ORG_ID_BRACKETS = "{{dealerOrganizationId}}";
  static CONTACT_ID_BRACKETS = "{{contactId}}";
  static USER_ID_BRACKETS = "{{userId}}";
  static readonly REPORT_ID_BRACKETS = "{{reportId}}";
  static readonly REPORT_ID = "reportId";
}
